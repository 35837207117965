@import "../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 160px 0;

  .row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;

    &.first {
      padding: 40px 0; 
      border-top: 1px solid rgba(0, 0, 0, 0.20); 
      border-bottom: 1px solid rgba(0, 0, 0, 0.20); 
    }

    &.second {
      padding: 160px 0 60px;
      align-items: flex-start;

      .titlebg {
        display: inline-flex;
        justify-content: center;
        padding: 8px 16px;
        background-color: #105739;
        color: #FFF;
        font-family: $inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .right {
        max-width: 875px;

        .title {
          color: #105739;
          font-family: $lato;
          font-size: 40px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -1.4px;
          padding: 0;
        }

        .text {
          color: rgba(0, 0, 0, 0.70);
          font-family: $inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
          margin-top: 20px;
        }
      }
    }
  }

  .title {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    color: #105739;
    font-family: $lato;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1.4px;
    max-width: 650px;
    padding-bottom: 50px;
  }

  .subtitle {
    color: #FFF;
    font-family: $lato;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1.4px;
    margin-top: 10px;
  }

  .text {
    color: rgba(0, 0, 0, 0.70);
    font-family: $inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; 
  }

  .list {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 50px;

    &Item {
      display: flex;
      // align-items: flex-end;
      flex-direction: column;
      gap: 20px;
      padding: 30px 40px 50px;
      border: 1px solid rgba(0, 0, 0, 0.20);

      &Icon {
        margin-bottom: 10px;
      }

      &Title {
        color: #105739;
        font-family: $inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      &Text {
        color: rgba(0, 0, 0, 0.70);
        font-family: $inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
      }
    }
  }

  .btnEnd {
    margin-top: 40px;
    text-align: right;
  }

  .btn {
    text-decoration: none;
    display: inline-flex;
    padding: 14px 30px;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    background: #8AE388;
    color: #105739;
    font-family: $inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 24px;
      padding: 10px 20px;
      border-width: 1px;
      letter-spacing: -0.2px;
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 120px 0;

    .row {
      flex-direction: column;
      align-items: flex-start;
  
      &.first {
        padding: 20px 0 40px;
        gap: 40px;
      }
  
      &.second {
        padding: 60px 0 0;
        align-items: flex-start;
  
        .titlebg {
          font-size: 16px;
          margin-bottom: 60px;
        }
  
        .right {
          max-width: unset;
  
          .title {
            font-size: 32px;
            margin-bottom: 20px;
          }
  
          .text {
            font-size: 16px;
            margin-top: 20px;
          }
        }
      }
    }

    .left {
      justify-content: flex-start;
      gap: 0;
    }


    .title {
      font-size: 32px;
      flex-direction: column;
      gap: 40px;
      padding: 0;
    }

    .text {
      font-size: 16px;
    }

    .list {
      gap: 20px;
      margin-top: 40px;
      flex-direction: column;
  
      &Item {
        padding: 30px 20px;
  
        &Title {
          font-size: 20px;
        }
  
        &Text {
          font-size: 14px;
        }
      }
    }

    .btnEnd {
      text-align: left;
    }
  }
}