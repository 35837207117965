@import '../../styles/variables';

.container {
    position: relative;
    max-width: 1352px;
    margin: 0 auto;
}

.wrap {
    position: relative;
    padding-top: 60px;
    gap: 20px;


    .formWrap {
        padding: 60px 50px 40px;
        width: 100%;
        background: #105739;

        @media screen and (max-width: $land-768) {
          padding: 30px 20px;
        }

        .title {
          color: #FFF;
          text-align: center;
          font-family: $lato;
          font-size: 40px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          letter-spacing: -1.4px;
        }

        .text {
          color: rgba(255, 255, 255, 0.70);
          text-align: center;
          font-family: $inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          max-width: 400px;
          margin: 10px auto 0;
        }

        .form {
          margin-top: 40px;

            .inputs {
                display: grid;
                grid-template-columns: repeat(2, 400px);
                grid-template-rows: repeat(3, 1fr);
                gap: 18px 51px;
                margin: 40px 0;
                justify-content: center;

                @media screen and (max-width: $land-768) {
                  display: flex;
                  flex-direction: column;
                }

                .group {
                    height: 100%;

                    &:nth-child(1) {
                        grid-column: 1/1;
                        grid-row: 1/1;
                    }

                    &:nth-child(2) {
                        grid-column: 2/2;
                        grid-row: 1/1;
                    }

                    &:nth-child(3) {
                        grid-column: 1/1;
                        grid-row: 2/2;
                    }

                    &:nth-child(4) {
                        grid-column: 1/1;
                        grid-row: 3/3;
                    }

                    &:nth-child(5) {
                        grid-column: 2/2;
                        grid-row: 2/4;
                    }
                }
            }

            .submit {
                display: flex;
                justify-content: center;
            }
        }
    }

    .input {
        width: 100%;
        height: 46px;
        padding: 0 30px;
        border-radius: 40px;
        background: transparent;

        color: #fff;
        font-family: $inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;
        border: 1px solid rgba(255, 255, 255, 0.30);

        &::placeholder {
            color: rgba(255, 255, 255, 0.50);
        }

        &.error {
            border-color: red;
        }
    }

    .phone {
        padding: 0;
        border: none;
        text-align: left !important;

        .inputClass {
            width: 100% !important;
            height: 46px !important;
            border-radius: 23px !important;
            background: transparent!important;
            border: 1px solid rgba(255, 255, 255, 0.30)!important;

            color: #fff !important;
            font-family: $inter;
            font-size: 16px !important;
            font-weight: 400 !important;
            line-height: 150% !important;

            &::placeholder {
                color: rgba(255, 255, 255, 0.50);
            }

            &.error {
                border-color: red !important;
            }
        }

        .buttonClass {
            background: transparent !important;
            border: none !important;

            &:hover {
                background: transparent;
            }

            :global(.selected-flag) {
                transition: all .1s;
                border-radius: 10px 0 0 10px !important;

                &:hover,
                &.open {
                    background: transparent !important;
                }

                &:global(.open) {
                    background: transparent !important;
                }
            }
        }

        .dropdownClass {
            color: #000 !important;
        }

        .searchClass {
            width: 100% !important;
            padding: 5px !important;

            input {
                border: none !important;
                width: 100%;
                margin-left: 0 !important;
            }
        }
    }

    .textarea {
        width: 100%;
        height: 100%;
        max-height: 400px;
        padding: 13px 30px;
        border-radius: 20px;
        background: transparent;
        resize: none;

        color: #fff;
        font-family: $inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;
        border: 1px solid rgba(255, 255, 255, 0.30);

        &::placeholder {
            color: rgba(255, 255, 255, 0.50);
        }

        &.error {
            border-color: red;
        }
    }

    .checkboxWrap {
        input {
            border-radius: 2px;
            background: #87AB9C;
            width: 12px;
            height: 12px;
            flex-shrink: 0;
        }

        .label {
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(255, 255, 255, 0.50);
            font-family: $inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            gap: 8px;
            margin-bottom: 15px;

            &.error {
                color: red;
            }
        }
    }

    .button {
        text-decoration: none;
        display: inline-flex;
        padding: 14px 30px;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        background: #8AE388;
        color: #105739;
        font-family: $inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        @media (max-width: 768px) {
            font-size: 16px;
            line-height: 24px;
            padding: 10px 20px;
            border-width: 1px;
            letter-spacing: -0.2px;
        }
    }
}

.popup{
    &Wrap {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba($color: #000, $alpha: 0.9);
        z-index: 99999999;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        top: 0;
        // pointer-events: none;
        gap: 30px;
        flex-direction: column;
        text-align: center;
    }

    &Content {
        padding: 50px;
        background-color: rgba(0,0,0,0.1);
    }

    &Text {
        font-size: 16px !important;
        line-height: 150%;
        color: #fff;
    }

    &Img {
        position: relative;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px auto;

        svg {
            transition: all .3s;
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 100px;
            border: 4px solid #fff;
            transform: scale(1.5);
            transition: all .3s;
        }
    }
}

.close {
    position: absolute;
    z-index: 20;
    width: 20px;
    height: 20px;
    top: 140px;
    right: 140px;
    cursor: pointer;
    transition: all .1s;

    @media(max-width: 700px) {
        top: 20px !important;
        right: 20px !important;
    }

    @media(max-height: 500px) {
        top: 10px;
        right: 10px;
    }

    &:hover {
        transform: scale(0.95);
    }

    &::before {
        content: '';
        position: absolute;
        width: 28px;
        height: 2px;
        background: #fff;
        transform: rotate(45deg);
        top: 49%;
        left: -5px;
    }

    &::after {
        content: '';
        position: absolute;
        width: 28px;
        height: 2px;
        background: #fff;
        transform: rotate(-45deg);
        top: 49%;
        left: -5px;
    }
}