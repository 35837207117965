@import '../../styles/variables';

.accordion {
  position: relative;
  width: 100%;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    color: rgba(0, 0, 0, 0.70);
    font-family: $inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    padding: 26px 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.20);

    &.titleActive {
      border-bottom-color: transparent;

      .icon {
        transform: rotate(90deg);
      }
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    height: 37px;
    flex-shrink: 0;
    cursor: pointer;
    transition: all 0.4s linear;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .content {
    color: rgba(0, 0, 0, 0.70);
    font-family: $inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding: 0 40px 20px;
    display: none;
    border-bottom: 1px solid transparent;


    &.contentActive {
      border-bottom-color: rgba(0, 0, 0, 0.20);
      display: block;
    }
  }

  @media (max-width: $mobile-width) {
    // padding: 50px 0;

    .title {
      font-size: 20px;
      column-gap: 20px;
      padding: 22px 0;
    }

    .content {
      font-size: 14px;
      padding-left: 0;
    }
  }
}