@import "../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 160px 0;

  .container {
    display: flex;
    align-items: stretch;
    gap: 20px;
    justify-content: space-between;
  }

  .blockText {
    max-width: 395px;
    flex-shrink: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .titlebg {
    display: inline-flex;
    justify-content: center;
    padding: 8px 16px;
    background-color: #105739;
    color: #FFF;
    font-family: $inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .title {
    color: #105739;
    font-family: $lato;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -1.4px;
    margin-top: 20px;
  }

  .slider {
    &Wrap {
      width: 100%;
    }
    &Btns {
      margin-top: auto;
      display: flex;
      gap: 30px;
      position: relative;
      z-index: 99;
    }

    &Btn {
      width: 45px;
      height: 45px;
      flex-shrink: 0;
      border-radius: 5px;

      img {
        width: 100%;
        height: 100%;
      }

      &.sliderBtnPrev {
        transform: rotate(-180deg);
      }

      &.inactive {
        opacity: 0.5;
      }
    }

    &Item {
      max-width: 438px;
    }
  }

  .slide {
    &Title {
      color: rgba(0, 0, 0, 0.70);
      font-family: $inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 20px 0 10px;
    }

    &List {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;

      &Text {
        position: relative;
        padding-left: 26px;
        color: rgba(0, 0, 0, 0.70);
        font-family: $inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; 

        &:before {
          position: absolute;
          content: '';
          width: 6px;
          height: 6px;
          top: 6px;
          left: 0;
          background-color: #105739;
          border-radius: 50%;
        }

        strong {
          font-weight: 600;
        }
      }
    }
  }

  .imgWrap {
    max-width: 438px;

    img {
      width: 100%;
    }
  }
  
  @media screen and (min-width: 769px) {
    .container {
      flex-wrap: wrap;

    }
    .slider {
      &Wrap {
        width: 63%;
        max-width: 975px;
      }

      &Btns {
        margin-top: -65px;
      }
    }
  }
  
  @media screen and (max-width: $land-768) {
    padding: 60px 0;

    .container {
      flex-direction: column;
    }

    .title {
      font-size: 32px;
    }

    .blockText {
      max-width: unset;
    }

    .slider {
      &Wrap {
        margin-top: 20px;
      }

      &Btns {
        justify-content: space-between;
      }
    }

    .slide {
      &Title {
        font-size: 20px;
        margin: 40px 0 13px;
      }
    }
  }
}