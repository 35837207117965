$mainbg: #FF7324;
$maintext: #202020;

$inter: "Inter", sans-serif;
$lato: "Lato", sans-serif;

$max-width: 1920px;
$main-width: 1220px;
$land-992: 992px;
$land-768: 768px;
$land-767: 767px;
$mobile-width: 767px;
$minwidth: 375px;