@import '../../styles/variables';

.wrap {
  position: relative;
  width: 100%;
  padding: 80px 0;

  .container {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }

  .imgWrap {
    max-width: 437px;
    width: 33%;
  }

  .title {
    color: #105739;
    font-family: $lato;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1.4px;
    padding-left: 40px;
    max-width: 560px;
    margin-bottom: 30px;
  }

  .right {
    width: 100%;
  }

  @media screen and (max-width: $land-768) {
    padding: 130px 0 60px;

    .container {
      gap: 30px;
      flex-direction: column-reverse;
    }

    .imgWrap {
      max-width: unset;
      width: 100%;

      img {
        width: 100%;
      }
    }

    .title {
      font-size: 32px;
      padding-left: 0;
      max-width: unset;
      margin-bottom: 18px;
    }
  }
}