@import '../../styles/variables';

.wrap {
  position: relative;
  width: 100%;
  padding: 75px 0;
  border: 0px solid rgba(255, 255, 255, 0.30);
  background: #105739;

  .container {
    display: flex;
    align-items: flex-start;
    gap: 60px;
  }

  .tab {

    &Buttons {
      width: 435px;
      overflow: visible;

      button {
        width: 100%;
        display: flex;
        padding: 10px 10px 10px 20px;
        border: none;
        cursor: pointer;
        color: #8AE388;
        font-family: $inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        background-color: #286B4F;

        &.tabActive {
          color: #105739;
          background-color: #fff;
        }
      }
    }

    &sContent {
      width: 100%;

    }

    &Item {
      display: none;
      grid-template-columns: repeat(2, 1fr);
      gap: 12px 20px;

      &.tabItemActive {
        display: grid;
      }

      &Title {
        color: #FFF;
        font-family: $inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 20px;
      }
    }

    &List {

      .listItem {
        position: relative;
        padding-left: 24px;
        margin-bottom: 12px;

        &:before {
          position: absolute;
          content: '';
          width: 8px;
          height: 8px;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          background-color: #8AE388;
          border-radius: 50%;
        }

        .contentText {
          color: rgba(255, 255, 255, 0.70);
          font-family: $inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
    }
  }

  @media (min-width: $land-767) {
    .tab {
      &Item {

        &Block {
          padding: 20px 10px;
          margin: 10px 0;
        }
  
        &.tabItemFirst {
          .tabItemBlock {
    
            &:nth-child(even) {
              padding-left: 100px;
              border-left: 1px solid rgba(255, 255, 255, 0.20);
            }
          }
        }
      }
    }
  }

  @media (max-width: $land-767) {
    padding: 40px 0;

    .container {
      flex-direction: column;
      gap: 20px;
    }

    .tab {
      &sContent {
        padding-top: 0;
      }
  
      &Buttons {
        grid-template-columns: 1fr;
        gap: 0;
        width: 100%;
  
        button {
          font-size: 20px;
        }
      }
  
      &Item {
        grid-template-columns: 1fr;
        gap: 0;

        &Block {
          margin-top: 40px;

          &.mont {
            margin-top: 0;
          }
        }

        &Title {
          font-size: 32px;
        }
  
        &Text {
          font-size: 14px;
          max-width: unset;
  
          p {
            margin-bottom: 12px;
          }
  
          span {
            margin-top: 12px;
          }
        }
      }
  
      &List {
        grid-template-columns: 1fr;
        gap: 12px;
  
        &Item {
          width: 100%;
          margin-top: 25px;
        }
  
        .listItem {
          padding-left: 18px;
  
          &:before {
            width: 6px;
            height: 6px;
          }
  
          .contentText {
            font-size: 14px;
          }
        }
      }
    }
  }
}