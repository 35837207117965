@import "../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  // background-color: $mainbg;
  padding: 0;
  min-height: 750px;
  height: 100%;
  margin-top: -60px;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: right;
    }
  }

  .container {
    min-height: 650px;
    height: 100%;
    padding: 160px 20px 80px;
  }

  .left {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    min-height: 500px;
    flex: 0 0 auto;
    position: relative;
    z-index: 2;
  }

  .title {
    color: #fff;
    font-family: $lato;
    font-size: 80px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    text-transform: capitalize;
  }

  .subtitle {
    color: #FFF;
    font-family: $lato;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1.4px;
    margin-top: 10px;
  }

  .text {
    color: #fff;
    font-family: $lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 110%;
    margin-top: 40px;
  }

  .list {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 50px;
    margin-top: 100px;

    &Item {
      display: flex;
      align-items: flex-end;
      gap: 4px;

      &Title {
        color: #FFF;
        font-family: $lato;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
      }

      &Text {
        color: rgba(255, 255, 255, 0.70);
        font-family: $inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; 
      }
    }
  }

  .btn {
    text-decoration: none;
    display: inline-flex;
    padding: 14px 30px;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    background: #8AE388;
    color: #105739;
    font-family: $inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 24px;
      padding: 10px 20px;
      border-width: 1px;
      letter-spacing: -0.2px;
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 115px 0 0;
    min-height: 850px;

    .container {
      min-height: 850px;
      padding: 0 20px;
    }

    .title {
      font-size: 40px;
      margin-bottom: 14px;
    }
    .subtitle {
      font-size: 32px;
      margin-top: 0;
    }
    .text {
      font-size: 16px;
      margin-top: 20px;
    }

    .list {
      gap: 20px;
      margin-top: 60px;
      flex-direction: column;
  
      &Item {
        gap: 5px;
  
        &Title {
          font-size: 32px;
        }
  
        &Text {
          font-size: 16px;
        }
      }
    }

    .imgWrap {
      top: unset;
      bottom: 0;
      right: 0;
      // width: 83%;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      img {
        width: 150%;
        height: auto;
        object-fit: cover;
        object-position: center;
        margin-left: -10%;
      }
    }
  }
}