@import "../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 160px 0;

  .contentGrid {
    display: flex;
    // grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
    gap: 100px 5px;
    justify-content: space-between;
  }

  .item {
    width: 32%;
    flex: 0 0 auto;

    img { 
      width: 100%;
    }
  }

  .titlebg {
    display: inline-flex;
    justify-content: center;
    padding: 8px 16px;
    background-color: #105739;
    color: #FFF;
    font-family: $inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .title {
    color: #105739;
    font-family: $lato;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1.4px;
  }

  .text {
    color: rgba(0, 0, 0, 0.70);
    font-family: $inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; 
    margin-top: 20px;
  }

  .list {
    display: flex;
    flex-direction: column;

    &Item {
      padding: 15px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.20);

      &Title {
        color: #105739;
        font-family: $inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        position: relative;
        padding-left: 20px;

        &:before {
          position: absolute;
          content: '';
          width: 6px;
          height: 6px;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          background-color: #8AE388;
          border-radius: 50%;
        }
      }

      &Text {
        color: rgba(0, 0, 0, 0.70);
        font-family: $inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-top: 5px;
      }
    }
  }
  
  @media screen and (max-width: $land-768) {
    padding: 60px 0;

    .contentGrid {
      flex-direction: column;
      gap: 20px;
    }

    .item {
      width: 100%;
    }
  
    .titlebg {
      font-size: 16px;
      margin-bottom: 60px;
    }

    .title {
      font-size: 32px;
    }

    .text {
      font-size: 16px;
    }

    .list {
      gap: 20px;
      flex-direction: column;
  
      &Item {
  
        &Title {
          font-size: 20px;
        }
  
        &Text {
          font-size: 14px;
        }
      }
    }
  }
}